
            @import 'src/styles/mixins.scss';
        
.mainContainer {
    position: relative;
    margin-top: 60px;
    @include lg {
        margin-top: 100px;
    }
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;

        background-color: #f3f6ff;

        z-index: -10;
    }
}
