
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.mainSection {
    position: relative;
    min-height: calc(100vh - 54px);

    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        background: linear-gradient(
            180deg,
            #e2e9ff 0%,
            rgba(226, 233, 255, 0) 100%
        );

        z-index: -1;
    }
}

.categories-wrapper {
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;

    gap: 15px;
    margin-top: 40px;
}

.category-box {
    width: 145px;
    height: 145px;
    background-color: white;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    border: 3px solid #4f96f6;
    align-items: center;
    transition: all 0.3s ease-in-out;
    img {
        filter: invert(56%) sepia(43%) saturate(3192%) hue-rotate(193deg)
            brightness(98%) contrast(96%);
    }
    @include sm {
        width: 170px;
        height: 170px;
    }
    > span {
        color: #4f96f6;
        font-size: 14px;
        @include sm {
            font-size: 16px;
        }
    }
    &:hover {
        background-color: #4f96f6;
        transition: all 0.3s ease-in-out;
        > span {
            color: #fff;
        }
        img {
            filter: invert(1) brightness(100);
        }
    }
}
.category-icon {
    :first-child {
        margin-top: 16px;
        margin-bottom: 3px;
        width: 70px;
        height: 70px;

        @include sm {
            margin-top: 26px;
            margin-bottom: 3px;
            width: 87px;
            height: 87px;
        }
    }
}
